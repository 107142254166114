'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.factory:DispatchFilters

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'DispatchFilters', [
    'DispatchGroups'
    'IncidentPriorities'
    'DispatchUnitStatus'
    'UnitType'
    '$log'
    '$q',
    '$filter',
    'RestUtils',
    '$translate'
    (DispatchGroups, IncidentPriorities, DispatchUnitStatus, UnitType, $log, $q, $filter, RestUtils, $translate) ->
      DispatchFiltersBase = {}

      ###
      # FilterConfig is an array to configure
      # the available filters on the dispatching map
      # Get the actual options somewhere else.
      # Some get a service variable that is a rest factory
      ###
      DispatchFiltersBase.FilterConfig = [
        {
          title: "app.dispatch.filtervehicles"
          machineName: "vehicle_filters"
          open: false
          filters: [
            {
              label: 'app.dispatch.dispatchstatus'
              key: 'dispatchStatus'
              machineName: "has_dispatch_unit"
              multiple: true
              single: true
            },
            {
              label: 'app.dispatch.dispatchgroup'
              machineName: "dispatch_group_id"
              service: DispatchGroups
              multiple: true
              single: false
              options: []
              sort: "label, ASC"
            },
            {
              label: 'app.dispatch.unittypes'
              machineName: "dispatch_unit_type_id"
              service: UnitType
              options: []
              multiple: true
              single: false
              match: (option) ->
                return {
                  'dispatchUnitType':
                    'id': option
                }
            },
            {
              label: 'app.dispatch.unitstatuses'
              machineName: "dispatch_unit_status_id"
              service: DispatchUnitStatus
              options: []
              multiple: true
              single: false
              match: (option) ->
                return {
                  'dispatchUnitStatus':
                    'id': option
                }
            },
          ]
        },
        {
          title: "app.dispatch.filterincidents"
          machineName: "incident_filters"
          open: false
          filters: [
            {
              label: 'app.dispatch.dispatchgroup'
              machineName: "dispatchGroup"
              service: DispatchGroups
              multiple: true
              single: false
              options: []
              sort: "label, ASC"
            },
            {
              label: 'app.dispatch.priorities'
              machineName: 'incidentPriority'
              service: IncidentPriorities
              multiple: true
              single: false
              options: []
              sort:  "weight,ASC"
            },
            {
              label: 'app.dispatch.incident-status'
              machineName: 'incidentDispatchStatus'
              multiple: true
              single: false
              options: [
                {'id': 'dispatched', 'label': 'app.dispatch.incident-filter.dispatched-incidents'},
                {'id': 'not_dispatched', 'label': 'app.dispatch.incident-filter.not-dispatched-incidents'}
              ]
            }
          ]
        }
      ]

      DispatchFiltersBase.getFilters = ($scope) ->
        promises = []
        angular.forEach @FilterConfig, (filterGroup) ->
          angular.forEach filterGroup.filters, (filter) ->
            params = {}
            if filter.service
              if filter.sort
                params.sort = filter.sort

              filterPromise = RestUtils.getFullList(filter.service, params)
              filterPromise.then (results)->
                filter.options = results

              promises.push filterPromise

        return $q.all promises

      DispatchFiltersBase
  ]
